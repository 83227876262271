<template>
  <div v-if="filterType">
    <v-text-field
      v-if="filterType.startsWith('TEXT_') || filterType.startsWith('MULTI_')"
      v-model="filterValue"
      @input="onInput"
      v-bind="$attrs"
      :label="value.name"
    ></v-text-field>

    <DateTimePickerField
      v-if="filterType.startsWith('DATETIME_')"
      v-model="filterValue"
      @input="onInput"
      v-bind="$attrs"
      :label="value.name"
      defaultTime="00:00"
    ></DateTimePickerField>

    <ClaimTypeField
      v-if="filterType == 'CLAIM_TYPE'"
      showKey
      v-model="filterValue"
      @input="onInput"
      v-bind="$attrs"
      :label="value.name"
    />

    <OrganizationField
      v-if="filterType == 'ORGANIZATION'"
      showKey
      v-model="filterValue"
      @input="onInput"
      v-bind="$attrs"
      :label="value.name"
    />

    <OrganizationTypeField
      v-if="filterType == 'ORGANIZATION_TYPE'"
      showKey
      v-model="filterValue"
      @input="onInput"
      v-bind="$attrs"
      :label="value.name"
    />

    <ParticipantField
      v-if="filterType == 'PARTICIPANT'"
      showKey
      v-model="filterValue"
      @input="onInput"
      v-bind="$attrs"
      :label="value.name"
    />

    <ParticipantTypeField
      v-if="filterType == 'PARTICIPANT_TYPE'"
      showKey
      v-model="filterValue"
      @input="onInput"
      v-bind="$attrs"
      :label="value.name"
    />
  </div>
</template>

<script>
import DateTimePickerField from "./DateTimePickerField.vue";
import ClaimTypeField from "./ClaimTypeField.vue";
import OrganizationTypeField from "./OrganizationTypeField.vue";
import ParticipantTypeField from "./ParticipantTypeField.vue";
import OrganizationField from "./OrganizationField.vue";
import ParticipantField from "./ParticipantField.vue";

export default {
  components: {
    ParticipantField,
    OrganizationField,
    ParticipantTypeField,
    OrganizationTypeField,
    ClaimTypeField,
    DateTimePickerField
  },
  name: "CustomReportFilterFormField",
  props: {
    value: Object
  },
  data: () => ({
    filterType: null,
    filterValue: ""
  }),
  methods: {
    onInput() {
      this.value.value = this.filterValue;
      this.$emit("input", this.value);
    }
  },
  watch: {
    customReportFilter() {
      this.filterType = this.value && this.value.filterType ? this.value.filterType.name : "";
      this.filterValue = this.value.value;
    },
    value() {
      this.filterType = this.value && this.value.filterType ? this.value.filterType.name : "";
      this.filterValue = this.value.value;
    }
  },
  created() {
    this.filterType = this.value && this.value.filterType ? this.value.filterType.name : "";
    this.filterValue = this.value.value;
  }
};
</script>
